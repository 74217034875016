import { Component, Input } from '@angular/core';
import { BaseComponent } from 'src/app/core/abstractions/base.component';
import { ACTION_CREATE, ACTION_DISABLE, STATUS_REJECTED } from '../../../../../app.constants';

@Component({
    selector: 'app-historical-document-view',
    templateUrl: './historical-document-view.component.html',
    styleUrls: [
        './historical-document-view.component.scss',
        '../../../approve-pending-changes/approve-pending-changes.component.scss',
    ],
})
export class HistoricalDocumentViewComponent extends BaseComponent {

    @Input() item: any;
    @Input() customIconClasses = '';

    getApprovalResult() {
        if (this.item && this.item.historicalData && this.item.historicalData.confirm) {
            return this.item.historicalData.confirm.result;
        }
    }

    getTitle() {
        if (this.item && this.item.historicalData) {
            if (this.item.historicalData.action === ACTION_CREATE) {
                return this.getApprovalResult() === STATUS_REJECTED ?
                    'approvals-history.historical-document.title.document-creation-rejected' :
                    'approvals-history.historical-document.title.document-creation-approved';
            }
            if (this.item.historicalData.action === ACTION_DISABLE) {
                return this.getApprovalResult() === STATUS_REJECTED ?
                    'approvals-history.historical-document.title.document-disable-rejected' :
                    'approvals-history.historical-document.title.document-disable-approved';
            }
        }
    }

    getText() {
        return this.getApprovalResult() === STATUS_REJECTED ?
            'approvals-history.historical-document.text.document-creation-rejected' :
            'approvals-history.historical-document.text.document-creation-approved';
    }

    hasHistoricalDataForThisItem() {
        return this.item && this.item.historicalData;
    }

}
