import { ActivatedRoute } from '@angular/router';
import {
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/core/abstractions/base.component';
import { HistoricalDataService } from '../../services/historical-data.service';

@Component({
    selector: 'app-historical-data-dropdown',
    templateUrl: './historical-data-dropdown.component.html',
    styleUrls: ['./historical-data-dropdown.component.scss'],
})
export class HistoricalDataDropdownComponent
    extends BaseComponent
    implements OnInit, OnDestroy
{
    @ViewChild('dropdownTitle') dropdownTitle: ElementRef;
    @ViewChild('dropdownDetail') dropdownDetail: ElementRef;
    @ViewChild('customDropdown') customDropdown: ElementRef;

    expanded = false;
    detailed = false;

    subscribe: Subscription;
    historicalDataObs: Observable<any>;

    constructor(
        private historicalDataService: HistoricalDataService,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
        this.historicalDataObs = this.historicalDataService.getHistoricalData();
    }

    toggle() {
        if (this.expanded && this.detailed) {
            this.close();
        } else if (this.expanded) {
            this.detailed = true;
        } else {
            this.expanded = true;
        }
    }

    ngOnDestroy(): void {
        this.subscribe?.unsubscribe();
    }

    getTooltipDetailMessage() {
        if (this.expanded) {
            return this.translate('approvals-history.historical-data.click-again');
        }
    }

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event): void {
        if (!this.customDropdown?.nativeElement.contains(event.target)) {
            this.close();
        }
    }

    close() {
        setTimeout(() => {
            this.expanded = false;
            this.detailed = false;
            this.dropdownTitle?.nativeElement.classList.add('slide-left');
        }, 400);
        this.dropdownTitle?.nativeElement.classList.remove('slide-left');
        this.dropdownTitle?.nativeElement.classList.add('slide-right');
        this.dropdownDetail?.nativeElement.classList.add('scale-down-tr');
    }

    loadAllChange(historicalGroupId) {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                historicalGroupId,
            },
            queryParamsHandling: 'merge'
        });
    }
}
