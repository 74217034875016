/* src/app/shared/components/approve-pending-changes/approve-pending-changes.component.scss */
.warning-icon {
  cursor: pointer;
}
.custom-dropdown {
  border-radius: 8px !important;
  width: 25vw;
  padding: 0;
  margin: 0;
}
.custom-dropdown ::ng-deep .overlay {
  width: 100%;
  border-radius: 6px;
}
.dropdown {
  z-index: 99 !important;
}
.confirm-action-container {
  display: flex;
  height: 100%;
}
.confirm-action-overlay {
  position: absolute;
  width: 25vw;
  height: 96%;
  margin-left: -1px;
  margin-top: -1px;
  background: white;
  z-index: 999;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.custom-dropdown-header,
.custom-dropdown-header-confirm-action {
  background-color: #F5A940;
  color: white;
  font-weight: 600;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.custom-dropdown-header-confirm-action {
  background-color: white;
}
.custom-dropdown-header {
  background-color: #F5A940;
}
.custom-dropdown-action-buttons {
  border-top: 2px solid #E1E8EE;
}
.invite-link-dropdown-container i:after {
  display: none;
}
.old-value,
.new-value {
  text-align: center;
  max-height: 50vh;
  overflow-y: auto;
}
.old-value .title,
.new-value .title {
  color: #5C656E;
  font-weight: 600;
  margin-bottom: 8px;
}
.old-value {
  background-color: #F2F2F2;
}
.btn-approve {
  background-color: #1BD14C;
  color: #ffffff;
  border-radius: 50%;
  padding: 3px;
  font-size: 16px !important;
  margin-top: 1px;
}
.btn-reject {
  background-color: #EB1E1E;
  border-radius: 50%;
  color: white;
  padding: 3px;
  font-size: 16px !important;
  margin-top: 1px;
}
.btn-close {
  font-size: 20px;
  color: white;
  float: right;
}
.btn-send {
  color: #F5A940;
  float: right;
  text-transform: uppercase;
  font-weight: bold;
}
.reject-reason {
  overflow: auto;
  resize: none;
  width: 100%;
  border: none;
  outline: none;
}
.dropdown-menu-arrow {
  position: absolute;
  right: 19px;
  top: -6px;
}
.dropdown-menu-arrow:before,
.dropdown-menu-arrow:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 9px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
  top: -1px;
}
.dropdown-menu-arrow:after {
  border-bottom-color: #F5A940;
}
.dropdown-menu-arrow:before {
  border-bottom-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-arrow-white:after {
  border-bottom-color: rgb(230, 230, 230);
}
.loader {
  width: 35px;
  height: 35px;
  border: 3px solid #c2c2c2;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.btn-close-approve-pending-changes {
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=approve-pending-changes.component-D65RRTAE.css.map */
