import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class InputMaskService {

    masks = {
        phonePlaceholder: '0000 000000',
        date: {
            placeholder: 'TT.MM.JJJJ',
            format: 'DD.MM.YYYY'
        }
    };

    constructor() {}

    get phonePlaceholder() {
        return this.masks.phonePlaceholder;
    }

    get dateMaskPlaceholder() {
        return this.masks.date.placeholder;
    }

    get dateMaskFormat() {
        return this.masks.date.format;
    }

}
