import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ApprovePendingChangesComponent} from './approve-pending-changes.component';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbDropdownModule,
        FormsModule,
        NgxSpinnerModule,
        MatTooltipModule
    ],
    declarations: [ApprovePendingChangesComponent],
    exports: [ApprovePendingChangesComponent],
})
export class ApprovePendingChangesModule {
}
