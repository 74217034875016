import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumericFieldDirective } from './numeric-field.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        NumericFieldDirective,
    ],
    exports: [
        NumericFieldDirective,
    ]
})
export class NumericFieldModule {
}
