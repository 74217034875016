import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Functions } from '@angular/fire/functions';
import { ActivatedRoute } from '@angular/router';
import * as lodash from 'lodash';
import { forkJoin, Observable, of, zip } from 'rxjs';
import { concatMap, filter, map, share, take } from 'rxjs/operators';
import {
    APPROVALS_HISTORY_COLLECTION,
    COMPANIES_COLLECTION
} from 'src/app/app.constants';
import { BaseService } from 'src/app/core/abstractions/base-service';
import { FireLoggingService } from '../../../../core/services/fire-logging.service';
import { UserService } from './../../../../core/services/user.service';
import { FilterModel } from './../../../models/filter.model';

@Injectable()
export class HistoricalDataService extends BaseService<any> {
    private historicalDataObs: Map<string, Observable<any>> = new Map();
    private approvalHistoryByGroup: Map<string, Observable<any>> = new Map();

    private collectionPath = `${COMPANIES_COLLECTION}/${this.userService.companyId}/${APPROVALS_HISTORY_COLLECTION}`;

    constructor(
        private userService: UserService,
        private ativatedRoute: ActivatedRoute,
        protected firestore: AngularFirestore,
        protected http: HttpClient,
        protected functions: Functions,
        protected fireLogging: FireLoggingService
    ) {
        super(firestore, http, functions, fireLogging);
    }

    /**
     * Get approval history data by identifiers in query params;
     *
     * @param collectionId - if present, it will only return data compatible with current collection id;
     * @param property - if present, it will only return data compatible with the property;
     * @param fullData - if present, validates the presence of references in newValue and oldValue;
     * @returns historical data;
     */
    getHistoricalData(
        collectionId?: string,
        property?: string,
        fullData?: boolean
    ) {
        let obs = this.ativatedRoute.queryParams.pipe(
            filter((queryParams) => queryParams.approvalHistoryId || queryParams.historicalGroupId),
            concatMap(({ approvalHistoryId, historicalGroupId }) => {
                if (historicalGroupId) {
                    return this.getAllApprovalHistoryByGroup(historicalGroupId);
                } else if (approvalHistoryId) {
                    return this.getApprovalHistoryById(approvalHistoryId);
                }
            }),
            map((historicalData) => {
                let data;
                if (Array.isArray(historicalData)) {
                    if (property) {
                        historicalData = historicalData.filter((e) => {
                            if (collectionId) {
                                const isSameCollection =
                                    e.referenceId === collectionId;
                                return (
                                    isSameCollection && e.property === property
                                );
                            }
                            return e.property === property;
                        });
                    }
                    data = historicalData.at(0);
                    if (data && data.historicalGroupId) {
                        delete data.historicalGroupId;
                    }
                } else if (collectionId) {
                    if (historicalData.referenceId === collectionId) {
                        if (historicalData.property === property || !property) {
                            data = historicalData;
                        } else if (!historicalData.property) {
                            data = historicalData;
                            data.newObject = true;
                        }
                    }
                } else if (historicalData.property === property || !property) {
                    data = historicalData;
                }
                return data;
            }),
            filter((e) => !!e) // Returns only when data is found
        );
        if (fullData) {
            obs = this.buildDeepValues(obs);
        }

        return obs;
    }

    /**
     * Get all approvals history grouped by historicalGroup identifier;
     *
     * @param customFilter - filter
     * @returns list fo historical data;
     */
    getAllApprovalHistoriesGrouped(customFilter: FilterModel) {
        return this.search(this.collectionPath, customFilter).pipe(
            map((approvals) => {
                return lodash
                    .chain(approvals)
                    .groupBy('historicalGroupId')
                    .map((values) => {
                        return { ...values[0], numberOfChanges: values.length };
                    })
                    .value();
            })
        );
    }

    /**
     * Get approval history by id, if the data has already been consulted,
     * the method does not perform the search again;
     *
     * @param approvalHistoryId - Historical change identifier
     * @returns Observable of approvals history
     */
    private getApprovalHistoryById(approvalHistoryId) {
        if (!this.historicalDataObs.has(approvalHistoryId)) {
            this.historicalDataObs.set(
                approvalHistoryId,
                this.getById(
                    approvalHistoryId,
                    `${COMPANIES_COLLECTION}/${this.userService.companyId}/${APPROVALS_HISTORY_COLLECTION}`
                ).pipe(
                    take(1),
                    share(), // Share is used to prevent all fields from calling firebase api again
                    map((historicalData) => {
                        historicalData.referenceId = historicalData?.reference?.id;
                        return historicalData;
                    })
                )
            );
        }
        return this.historicalDataObs.get(approvalHistoryId);
    }

    /**
     * Get approval history by group id, if the data has already been consulted,
     * the method does not perform the search again;
     *
     * @param historicalGroupId - Group identifier
     * @returns Observable of approval history
     */
    private getAllApprovalHistoryByGroup(historicalGroupId: string) {
        if (!this.approvalHistoryByGroup.has(historicalGroupId)) {
            const historicalGroupFilter = new FilterModel();
            historicalGroupFilter.clauses.push({
                fieldPath: 'historicalGroupId',
                opStr: '==',
                value: historicalGroupId,
            });
            this.approvalHistoryByGroup.set(
                historicalGroupId,
                this.search(this.collectionPath, historicalGroupFilter).pipe(
                    take(1),
                    share(),
                    map((historicalData) => {
                        return historicalData.map((data) => {
                            data.referenceId = data.reference.id;
                            return data;
                        });
                    })
                )
            );
        }
        return this.approvalHistoryByGroup.get(historicalGroupId);
    }

    get approvalHistoryId(): Observable<string> {
        return this.ativatedRoute.queryParams.pipe(
            map((queryData: any) => queryData.approvalHistoryId)
        );
    }

    private buildDeepValues(obs: Observable<any>) {
        return obs.pipe(
            concatMap((historicalData) => {
                const newValue = this.getRawValue(historicalData.newValue);
                const previousValue = this.getRawValue(historicalData.previousValue);

                return forkJoin({ newValue, previousValue }).pipe(
                    map((res) => {
                        historicalData.newOriginalValue = historicalData.newValue;
                        historicalData.previousOriginalValue = historicalData.previousValue;
                        historicalData.newValue = res.newValue;
                        historicalData.previousValue = res.previousValue;
                        return historicalData;
                    })
                );
            })
        );
    }

    private getRawValue(value) {
        if (Array.isArray(value) && value[0]?.firestore) {
            return zip(
                ...value.map((data) => data.get().then((e) => e.data()))
            );
        } else if (value?.firestore) {
            return value.get();
        } else {
            return of(value);
        }
    }
}
