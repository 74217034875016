import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

    title: string;
    text: string;
    isConfirmationDialog = true;
    yesBtnTitle = 'system.yes';
    noBtnTitle = 'system.no';
    doneBtnTitle = 'system.done';
    disableClose = false;
    doneDialogCloseValue = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = data.title;
        this.text = data.text;
        this.isConfirmationDialog = data.isConfirmationDialog !== undefined ? data.isConfirmationDialog : this.isConfirmationDialog;
        this.yesBtnTitle = data.yesBtnTitle !== undefined ? data.yesBtnTitle : this.yesBtnTitle;
        this.noBtnTitle = data.noBtnTitle !== undefined ? data.noBtnTitle : this.noBtnTitle;
        this.doneBtnTitle = data.doneBtnTitle !== undefined ? data.doneBtnTitle : this.doneBtnTitle;
        this.disableClose = data.disableClose !== undefined ? data.disableClose : this.disableClose;
        this.doneDialogCloseValue = data.doneDialogCloseValue !== undefined ? data.doneDialogCloseValue : this.doneDialogCloseValue;
    }

}
