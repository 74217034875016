import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Renderer2
} from '@angular/core';
import { TOOLTIP_SIZE_BY_LENGTH } from 'src/app/app.constants';
@Directive({
    selector: '[appEllipsify]',
})
export class EllipsifyDirective implements OnInit, AfterViewInit {
    @Input() enableEllipsify = true;
    @Input() enableTooltip = true;
    @Input() tooltipPos:
        | 'up'
        | 'left'
        | 'right'
        | 'down'
        | 'up-left'
        | 'up-right'
        | 'down-left'
        | 'down-right' = 'up';

    domElement: any;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit(): void {
        if (this.enableEllipsify) {
            this.domElement = this.elementRef.nativeElement;
            const ellipsis = {
                display: 'block',
                'text-overflow': 'ellipsis',
                overflow: 'hidden',
                'white-space': 'nowrap',
            };

            Object.keys(ellipsis).forEach((element) => {
                this.renderer.setStyle(
                    this.domElement,
                    `${element}`,
                    ellipsis[element]
                );
            });
        }
    }

    ngAfterViewInit(): void {
        if (this.enableEllipsify) {
            this.renderer.setProperty(this.domElement, 'scrollTop', 1);
            this.renderer.setProperty(this.domElement, 'title', '');
            if (this.enableTooltip) {
                this.setToolTip();
            }
        }
    }

    @HostListener('mouseover')
    setToolTip() {
        if (this.enableTooltip) {
            let text = this.domElement.textContent;
            if (text && this.domElement.offsetWidth < this.domElement.scrollWidth) {
                text = text.trim();
                const needTooltipSize = text.split(/(\s+)/).length > 1;
                const parent = this.domElement.parentElement;
                this.renderer.setAttribute(
                    parent,
                    'data-balloon-pos',
                    this.tooltipPos
                );
                if (needTooltipSize) {
                    this.renderer.setAttribute(
                        parent,
                        'data-balloon-length',
                        TOOLTIP_SIZE_BY_LENGTH(text)
                    );
                }
                this.renderer.setAttribute(parent, 'aria-label', text);
                this.renderer.addClass(parent, 'tooltip-pewter');
                this.renderer.addClass(parent, 'tooltip-text');
            }
        }
    }
}
