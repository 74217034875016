import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AsYouType } from 'libphonenumber-js';

@Directive({
    selector: '[appPhoneMask]'
})
export class PhoneMaskDirective {

    constructor(public ngControl: NgControl) { }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        this.onInputChange(event, false);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event) {
        this.onInputChange(event.target.value, true);
    }

    onInputChange(event, backspace) {
        const asYouType: AsYouType = new AsYouType('DE');

        if (event && asYouType) {
            let newVal = event;

            if (backspace && newVal.length <= 6) {
                newVal = newVal.substring(0, newVal.length - 1);
            }

            newVal = asYouType.input(newVal);
            let typeNumber;
            if (asYouType.getNumber()) {
                typeNumber = asYouType.getNumber().number;
            } else {
                this.ngControl.valueAccessor.writeValue('');
                return;
            }

            const final = new AsYouType('DE').input(String(typeNumber));
            this.ngControl.valueAccessor.writeValue(final);
        }
    }
}
