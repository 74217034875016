import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { HistoricalDataDropdownComponent } from './components/historical-data-tooltip/historical-data-dropdown.component';
import { HistoricalDataViewComponent } from './components/historical-data-view/historical-data-view.component';
import { HistoricalDataService } from './services/historical-data.service';
import { HistoricalDocumentViewComponent } from './components/historical-document-view/historical-document-view.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        HistoricalDataDropdownComponent,
        HistoricalDataViewComponent,
        HistoricalDocumentViewComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatTooltipModule,
        MatRippleModule,
        NgbDropdownModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    exports: [
        HistoricalDataDropdownComponent,
        HistoricalDataViewComponent,
        HistoricalDocumentViewComponent,
    ],
    providers: [
        HistoricalDataService,
        provideNgxMask()
    ],
})
export class HistoricalDataModule {}
