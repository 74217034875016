/* src/app/shared/components/historical-data/components/historical-data-tooltip/historical-data-dropdown.component.scss */
.hist-dropdown {
  position: absolute;
  right: 65px;
  width: 100%;
}
.hist-dropdown #all-changes {
  text-decoration: underline;
}
.hist-dropdown .dropdown-icon {
  z-index: 999;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  box-shadow: -1px 4px 8px -4px grey;
}
.hist-dropdown .dropdown-title {
  z-index: 998;
  background-color: white;
  box-shadow: -1px 4px 8px -4px grey;
  height: 35px;
  margin-right: -1%;
  border-radius: 9px 0 0 9px;
  padding-right: 2%;
  padding-left: 1%;
}
.hist-dropdown .dropdown-title p {
  margin-bottom: 0;
  white-space: nowrap;
}
.hist-dropdown .dropdown-detail {
  background-color: white;
  box-shadow: -1px 4px 8px -4px grey;
  border-radius: 0 0 9px 9px;
  padding-right: 1%;
  position: absolute;
  top: 40px;
  right: 35px;
  z-index: 997;
  min-width: 245px;
  max-width: 300px;
}
.hist-dropdown .dropdown-detail .dot {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 35%;
  border-radius: 7px;
  align-self: center;
}
.hist-dropdown .slide-right {
  -webkit-animation: hist-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: hist-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.hist-dropdown .slide-left {
  -webkit-animation: hist-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: hist-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.hist-dropdown .scale-up-tr {
  display: initial !important;
  -webkit-animation: hist-scale-up-tr 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: hist-scale-up-tr 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  transform-style: preserve-3d;
}
.hist-dropdown .scale-down-tr {
  display: initial !important;
  -webkit-animation: hist-scale-down-tr 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: hist-scale-down-tr 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  transform-style: preserve-3d;
}
@-webkit-keyframes hist-slide-right {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}
@keyframes hist-slide-right {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}
@-webkit-keyframes hist-slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes hist-slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@-webkit-keyframes hist-scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
@keyframes hist-scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
@-webkit-keyframes hist-scale-down-tr {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
@keyframes hist-scale-down-tr {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
.hist-dropdown .process {
  background: #de697b;
  color: white;
}
.hist-dropdown .confirm {
  background: #f0bb72;
  color: white;
}
.hist-dropdown .inform {
  background: #5173cc;
  color: white;
}
/*# sourceMappingURL=historical-data-dropdown.component-7JDEPAQZ.css.map */
