<div #tooltip>
    <!----------------- ICONS TO SHOW TO THE USER WHO HAS PERMISSION TO APPROVE -------------------->

    <!-- APPROVAL REQUEST ABOUT UPDATE -->
    <div *ngIf="item && property && item?.pendentApproval?.action === 'UPDATE' && hasPermissionToApprove
        && hasPendentApprovalForThisProperty() && isNotHistory()"
        class="invite-link-dropdown-container"
        [matTooltip]="'approvals-requests.advise-pendent-changes-to-approve' | translate">
        <div ngbDropdown
             #customDropdown="ngbDropdown"
             container="body"
             [autoClose]="'outside'"
             placement="bottom-right">
            <i class="material-icons warning-icon"
               ngbDropdownToggle>
                warning
            </i>
            <div ngbDropdownMenu class="custom-dropdown" aria-labelledby="invite-link-dropdown">
                <span class="dropdown-menu-arrow"
                      [ngClass]="{'dropdown-menu-arrow-submitting': submitting}">
                </span>

                <div>
                    <div class="row mr-0 ml-0 pt-3 pb-3 pl-6 pr-7 custom-dropdown-header">
                        <div class="col-12 pl-0 pr-0 text-left">
                            <span translate>approvals-requests.feedback-modal.title</span>
                        </div>
                        <div class="btn-close-approve-pending-changes">
                            <i class="material-icons" (click)="close()">close</i>
                        </div>
                    </div>
                    <div class="row mr-0 ml-0">
                        <div class="col-6 old-value pt-4 pb-4 pl-6 pr-6">
                            <span class="title d-block" translate>approvals-requests.feedback-modal.current-value</span>
                            <span class="loader" *ngIf="loadingValues"></span>
                            <span class="custom-dropdown-content"
                                  *ngIf="!loadingValues">{{getPendentValue(oldValue)}}</span>
                        </div>
                        <div class="col-6 new-value pt-4 pb-4 pl-6 pr-6">
                            <span class="title d-block"
                                  translate>approvals-requests.feedback-modal.requested-value</span>
                            <span class="loader" *ngIf="loadingValues"></span>
                            <span class="custom-dropdown-content"
                                  *ngIf="!loadingValues">{{getPendentValue(newValue)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- APPROVAL REQUEST ABOUT CREATION OR REMOVAL -->

    <button class="btn-pulse-warning pulse-warning"
            *ngIf="item &&
            isValid(item) &&
            item.pendentApproval &&
            hasPermissionToApprove &&
            !property
            && isNotHistory()"
            type="button"
            (click)="details.emit()"
            [matTooltip]="'approvals-requests.advise-pendent-changes-to-approve' | translate">
        <i class="material-icons">warning</i>
    </button>


    <!----------------- ICONS TO SHOW TO THE USERS WHO DOESN'T HAS PERMISSIONS TO APPROVE -------------------->

    <!-- APPROVAL REQUEST ABOUT CREATION OR REMOVAL -->
    <button class="btn-pulse-warning pulse-warning"
            *ngIf="item && isValid(item) && item.pendentApproval && !hasPermissionToApprove && !property && isNotHistory() && hasPermissionToEdit"
            type="button"
            (click)="details.emit()"
            [matTooltip]="'approvals-requests.advise-pendent-changes-to-approve-to-user-with-no-permission' | translate">
        <i class="material-icons">warning</i>
    </button>

    <!-- APPROVAL REQUEST ABOUT UPDATE -->
    <div
        *ngIf="item && property && item?.pendentApproval?.action === 'UPDATE' && !hasPermissionToApprove && hasPendentApprovalForThisProperty() && isNotHistory() && hasPermissionToEdit"
        (click)="details.emit()">
        <i class="material-icons warning-icon"
           [matTooltip]="'approvals-requests.advise-pendent-changes-to-approve-to-user-with-no-permission' | translate">
            warning
        </i>
    </div>
</div>
