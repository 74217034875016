import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EllipsifyDirective } from './ellipsify.directive';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        EllipsifyDirective,
    ],
    exports: [
        EllipsifyDirective,
    ]
})
export class EllipsifyModule {
}
