/* src/app/shared/components/historical-data/components/historical-data-view/historical-data-view.component.scss */
.schedule-icon {
  color: #6c6c6c;
}
.custom-dropdown-header {
  background-color: #275fa1 !important;
}
.old-value,
.new-value {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.custom-dropdown-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.dropdown-menu-arrow:after {
  border-bottom-color: #275fa1 !important;
}
.dropdown-toggle::after {
  display: none;
}
.property-spinner {
  top: 24px;
  position: absolute;
  right: 12px;
}
.icon-help-historical-data {
  font-size: 20px;
  margin-top: 2px;
  margin-left: 3px;
}
.pulse-icon {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  -webkit-animation: pulse-animation 2s infinite;
  animation: pulse-animation 2s infinite;
}
.pulse-icon:hover {
  animation: none;
}
@-webkit-keyframes pulse-animation {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse-animation {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
/*# sourceMappingURL=historical-data-view.component-CUVEZRTG.css.map */
