<finstein-base-modal [disableClose]="disableClose" [title]="title" bodyClass="none">
    <finstein-circle-info header></finstein-circle-info>
    <finstein-plain-text-content content [text]="text"></finstein-plain-text-content>
    <finstein-action-confirm actions
        [type]="isConfirmationDialog ? 'confirm' : 'done'"
        [yesBtnTitle]="yesBtnTitle"
        [noBtnTitle]="noBtnTitle"
        [doneBtnTitle]="doneBtnTitle"
        [doneDialogCloseValue]="doneDialogCloseValue">
    </finstein-action-confirm>
</finstein-base-modal>
