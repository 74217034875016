<div *ngIf="hasHistoricalDataForThisItem()" #tooltip>
    <div class="invite-link-dropdown-container">
        <div ngbDropdown #customDropdown="ngbDropdown" container="body" placement="bottom-right">
            <i class="material-icons cursor-pointer schedule-icon" [ngClass]="customIconClasses"
               [matTooltip]="'approvals-history.historical-data.view-historical-data'| translate"
               ngbDropdownToggle>
                schedule
            </i>
            <ng-container>
                <div ngbDropdownMenu class="custom-dropdown" aria-labelledby="invite-link-dropdown">
                    <span class="dropdown-menu-arrow"></span>
                    <div class="row mr-0 ml-0 pt-3 pb-3 pl-6 pr-6 custom-dropdown-header">
                        <div class="col-12 pl-0 pr-0 text-left">
                            <span>{{getTitle() | translate}}</span>
                        </div>
                    </div>
                    <div class="row mr-0 ml-0">
                        <div class="col-12 pt-4 pb-4 pl-6 pr-6">
                            <span class="title d-block">{{getText() | translate}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
