/* src/app/shared/components/historical-data/components/historical-document-view/historical-document-view.component.scss */
.schedule-icon {
  color: #6c6c6c;
}
.custom-dropdown-header {
  background-color: #275fa1 !important;
}
.dropdown-menu-arrow:after {
  border-bottom-color: #275fa1 !important;
}
.dropdown-toggle::after {
  display: none;
}
.property-spinner {
  top: 24px;
  position: absolute;
  right: 12px;
}
.icon-help-historical-data {
  font-size: 20px;
  margin-top: 2px;
  margin-left: 3px;
}
/*# sourceMappingURL=historical-document-view.component-RDFTVKBP.css.map */
