/* src/app/core/components/input/input.component.scss */
.input-icon {
  margin-left: -45px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
input:disabled {
  color: #494949;
}
.empty-errors {
  border: 1px solid #ced4da !important;
}
.icon-required {
  position: absolute;
  color: #DC3545;
  right: 1%;
  top: 42%;
  z-index: 1;
}
.input-group span.input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #e9ecef;
  color: #808080;
}
.input-group input[type=color] {
  padding: 3px;
  max-width: 32px;
}
.input-complement-with-label {
  position: absolute;
  right: 7px;
  top: 2.5rem;
  cursor: pointer;
  pointer-events: auto;
}
.input-complement-without-label {
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
  pointer-events: auto;
}
@media (max-width: 1900px) {
  .input-complement-with-label {
    top: calc(13px + 1.25rem);
  }
}
/*# sourceMappingURL=input.component-DGXHUAZW.css.map */
