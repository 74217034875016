import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../core/abstractions/base.component';
import { STATUS_APPROVED, STATUS_INACTIVE, STATUS_REJECTED } from '../../../app.constants';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import * as lodash from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-approve-pending-changes',
    templateUrl: './approve-pending-changes.component.html',
    styleUrls: ['./approve-pending-changes.component.scss']
})
export class ApprovePendingChangesComponent extends BaseComponent implements OnInit {

    @ViewChild('customDropdown', {static: false}) customDropdown: NgbDropdown;

    @Input() item;
    @Input() form: UntypedFormGroup | AbstractControl;
    @Input() controlName: string;
    @Input() show = true;
    @Input() property; // Property default to set and get the values (newData and oldData)
    /**
     * (Optional) Property to set the new data value in another place. In hte bookkeeping-keys, for example, when the
     * service is "isTaxable" the property ot find the new value is "tax.{property}", but we have to set this value in
     * the root of item like this "item[property]" to work correctly in the bookkeeping keys page.
     */
    @Input() propertyToSetValue?: string;
    @Input() permissionToApprove: string;
    @Input() customShowValue: any; // Function to personalize the presentation of the oldValue and newValue

    @Output() details: EventEmitter<any> = new EventEmitter<any>();

    submitting = false;

    oldValue: any;
    newValue: any;
    loadingValues = false;
    emptyValues = {
        ['Leer']: 'Leer',
        ['Empty']: 'Empty'
    };
    hasPermissionToApprove = false;
    hasPermissionToEdit = false;

    constructor(private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.hasPermissionToApprove = this.userService.hasAnyAuthority([
            `confirm#holder#${this.permissionToApprove}`,
            `confirm#standIn#${this.permissionToApprove}`,
        ]);

        this.hasPermissionToEdit = this.userService.hasAnyAuthority([
            `process#holder#${this.permissionToApprove}`,
            `process#standIn#${this.permissionToApprove}`
        ]);

        const data = this.findDataByPropertyInBackup();
        if (data && !this.newValue && !this.oldValue && !this.loadingValues) {
            this.loadingValues = true;
            forkJoin({
                newValue: this.getValue(data.newValue),
                oldValue: this.getValue(data.previousValue)
            }).subscribe(({oldValue, newValue}) => {
                this.newValue = newValue;
                this.oldValue = oldValue;
                this.loadingValues = false;
                if (this.form) {
                    this.form.get(this.controlName).setValue(data.newValue);
                }
                if (!this.form && this.item) {
                    lodash.set(this.item, this.property, data.newValue);
                    if (this.propertyToSetValue) {
                        lodash.set(this.item, this.propertyToSetValue, data.newValue);
                    }
                }
            });
        }
        if (data && this.form) {
            this.form.get(this.controlName).setValue(data.newValue);
        }
        if (data && !this.form && this.item) {
            lodash.set(this.item, this.property, data.newValue);
            if (this.propertyToSetValue) {
                lodash.set(this.item, this.propertyToSetValue, data.newValue);
            }
        }
    }

    get STATUS_REJECTED() {
        return STATUS_REJECTED;
    }

    get STATUS_APPROVED() {
        return STATUS_APPROVED;
    }

    getValue(data: any): Observable<any> {
        return new Observable((observer) => {
            if (Array.isArray(data)) {
                const listPromises = [];
                for (const obj of data) {
                    if (obj?.get) { // Document reference
                        listPromises.push(obj.get().then(doc => ({id: doc.id, ...doc.data()})));
                    }
                }
                if (listPromises.length > 0) {
                    Promise.all(listPromises).then((values) => {
                        this.resolveData(values).subscribe((res) => {
                            observer.next(res);
                        });
                    });
                } else {
                    this.resolveData(data).subscribe((res) => {
                        observer.next(res);
                    });
                }
            } else {
                this.resolveData(data).subscribe((res) => {
                    observer.next(res);
                });
            }
        }).pipe(take(1));
    }

    resolveData(data: any) {
        return new Observable((observer) => {
            if (data?.get) { // Document reference
                data.get().then(docRef => {
                    const doc = {id: docRef.id, ...docRef.data()};
                    if (this.customShowValue) {
                        observer.next(this.customShowValue(doc));
                    } else if (lodash.isNil(doc) || lodash.isEmpty(doc)) {
                        observer.next(this.translate('approvals-requests.feedback-modal.empty-value'));
                    } else {
                        observer.next(doc);
                    }
                });
            } else {
                if (this.customShowValue) {
                    observer.next(this.customShowValue(data));
                } else if (typeof data === 'boolean') {
                    observer.next(data ? this.translate('system.yes') : this.translate('system.no'));
                } else if (lodash.isNil(data) || (!lodash.isNumber(data) && lodash.isEmpty(data))) {
                    observer.next(this.translate('approvals-requests.feedback-modal.empty-value'));
                } else {
                    observer.next(data);
                }
            }
        }).pipe(take(1));
    }

    close() {
        if (this.customDropdown) {
            this.customDropdown.close();
        }
    }

    findDataByPropertyInBackup() {
        if (this.item && this.property && this.item.pendentApproval && this.item.pendentApproval.data) {
            return this.item.pendentApproval.data.find((item: any) => item.property === this.property);
        }
    }

    hasPendentApprovalForThisProperty() {
        if (!this.userService.loggedUser.finsteinUser && this.item && this.property && this.item.pendentApproval
            && this.item.pendentApproval.data) {
            return !!this.item.pendentApproval.data.find((item: any) => item.property === this.property);
        }
    }

    isValid(item) {
        return item.status !== STATUS_INACTIVE && item.status !== STATUS_REJECTED;
    }

    isNotHistory() {
        return lodash.isNil(this.activatedRoute.snapshot.queryParams.approvalHistoryId);
    }

    getPendentValue(value: string): string {
        if (Array.isArray(value)) {
            return value.join(', ');
        }
        return value && !this.emptyValues[value] ? value : this.translate('approvals-requests.feedback-modal.empty-value');
    }

}
