<div>
    <div *ngIf="type === 'DROPDOWN' && hasHistoricalDataForThisProperty()"
         class="invite-link-dropdown-container"
         finsteinTooltip="approvals-history.historical-data.view-historical-data">
        <div ngbDropdown
             #customDropdown="ngbDropdown"
             container="body"
             [autoClose]="'outside'"
             placement="bottom-right">
            <i class="material-icons cursor-pointer schedule-icon pulse-icon" ngbDropdownToggle>
                schedule
            </i>
            <div ngbDropdownMenu class="custom-dropdown" aria-labelledby="invite-link-dropdown">
                <div>
                    <div class="row mr-0 ml-0 pt-3 pb-3 pl-6 pr-7 custom-dropdown-header">
                        <div class="col-12 pl-0 pr-0 text-left">
                            <span>{{getTitle() | translate}}</span>
                        </div>
                        <div class="btn-close-approve-pending-changes">
                            <i class="material-icons" (click)="close()">close</i>
                        </div>
                    </div>

                    <!-- IF THE APPROVAL REQUEST WAS APPROVED -->
                    <div class="row mr-0 ml-0" *ngIf="getFeedbackResult() !== 'REJECTED'">
                        <div class="col-6 old-value pt-4 pb-4 pl-6">
                            <span class="title d-block" translate>approvals-requests.feedback-modal.old-value</span>
                            <span class="custom-dropdown-content">{{getCustomValue(oldValue)}}</span>
                        </div>
                        <div class="col-6 new-value pt-4 pb-4 pl-6 pr-6">
                            <span class="title d-block" translate>approvals-requests.feedback-modal.new-value</span>
                            <span class="custom-dropdown-content">{{getCustomValue(newValue)}}</span>
                        </div>
                    </div>

                    <!-- IF THE APPROVAL REQUEST WAS REJECTED -->
                    <div class="row mr-0 ml-0" *ngIf="getFeedbackResult() === 'REJECTED'">
                        <div class="col-6 old-value pt-4 pb-4 pl-6">
                            <div class="d-flex justify-content-center">
                                <span class="title d-block" translate>
                                    approvals-requests.feedback-modal.rejected-value
                                </span>
                                <i [matTooltip]="'approvals-requests.feedback-modal.rejected-value-tooltip' | translate"
                                   class="material-icons icon-help-historical-data">
                                    help
                                </i>
                            </div>
                            <span class="custom-dropdown-content">{{getCustomValue(newValue)}}</span>
                        </div>
                        <div class="col-6 new-value pt-4 pb-4 pl-6 pr-6">
                            <div class="d-flex justify-content-center">
                                <span class="title d-block" translate>
                                    approvals-requests.feedback-modal.current-value
                                </span>
                                <i [matTooltip]="'approvals-requests.feedback-modal.current-value-tooltip' | translate"
                                   class="material-icons icon-help-historical-data">
                                    help
                                </i>
                            </div>
                            <span class="custom-dropdown-content">{{getCustomValue(oldValue)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <i *ngIf="type === 'BUTTON'"
       (click)="details.emit()"
       class="material-icons cursor-pointer schedule-icon pulse-icon"
       finsteinTooltip="approvals-history.historical-data.view-historical-data">
        schedule
    </i>
</div>


