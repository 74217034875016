import { InputMaskService } from 'src/app/core/services/input-mask.service';
import {
    Directive,
    ElementRef,
    HostListener,
    OnInit,
    Renderer2,
} from '@angular/core';
import Inputmask from 'inputmask';

@Directive({
    selector: '[appInputDateMask]',
})
export class InputDateMaskDirective implements OnInit {
    domElement: any;
    inputMask = new Inputmask('99.99.9999', {
        showMaskOnFocus: false,
        showMaskOnHover: false
    });

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private inputMaskService: InputMaskService
    ) {
        this.domElement = this.el.nativeElement;
    }

    ngOnInit(): void {
        this.renderer.setAttribute(this.domElement, 'autocomplete', 'off');
        this.renderer.setAttribute(
            this.domElement,
            'placeholder',
            this.inputMaskService.dateMaskPlaceholder
        );
        this.inputMask.mask(this.domElement);
    }

}
