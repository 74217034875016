import { NumericFieldModule } from './../../directives/numeric-field/numeric-field.module';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { InputErrorModule } from '../input-error/input-error.module';
import { InputComponent } from './input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PhoneMaskModule } from '../../directives/phone-mask/phone-mask.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { InputDateMaskModule } from '../../directives/input-date-mask/input-date-mask.module';
import { EllipsifyModule } from '../../directives/ellipsify/ellipsify.module';
import {HistoricalDataModule} from '../../../shared/components/historical-data/historical-data.module';
import {
    ApprovePendingChangesModule
} from '../../../shared/components/approve-pending-changes/approve-pending-changes.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        InputErrorModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        PhoneMaskModule,
        CurrencyMaskModule,
        InputDateMaskModule,
        EllipsifyModule,
        NumericFieldModule,
        HistoricalDataModule,
        ApprovePendingChangesModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    declarations: [InputComponent],
    exports: [InputComponent, InputErrorModule],
    providers: [provideNgxMask()]
})
export class InputModule {
}
