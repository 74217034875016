import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import * as lodash from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/core/abstractions/base.component';
import { STATUS_REJECTED } from '../../../../../app.constants';

@Component({
    selector: 'app-historical-data-view',
    templateUrl: './historical-data-view.component.html',
    styleUrls: ['./historical-data-view.component.scss', '../../../approve-pending-changes/approve-pending-changes.component.scss']
})
export class HistoricalDataViewComponent extends BaseComponent implements OnInit, OnDestroy {

    @ViewChild('customDropdown', {static: false}) customDropdown: NgbDropdown;

    @Input() item;
    @Input() type: 'BUTTON' | 'DROPDOWN' = 'DROPDOWN';
    @Input() form: UntypedFormGroup | AbstractControl;
    @Input() controlName: string;
    @Input() show = true;
    @Input() property;
    @Input() customShowValue: any; // Function to personalize the presentation of the oldValue and newValue

    @Output() details: EventEmitter<any> = new EventEmitter<any>();

    oldValue: any;
    newValue: any;
    loadingValues = false;

    updateInterval;

    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        const data = this.findDataByPropertyInBackup();
        if (data && !this.newValue && !this.oldValue && !this.loadingValues) {
            this.loadingValues = true;
            forkJoin({
                newValue: this.getValue(data.newValue),
                oldValue: this.getValue(data.previousValue)
            }).subscribe(({oldValue, newValue}) => {
                this.newValue = newValue;
                this.oldValue = oldValue;
                this.loadingValues = false;
                if (lodash.isNil(this.oldValue) && this.form) {
                    this.form.get(this.controlName).setValue(this.newValue);
                }
            });
        }
        if (data && lodash.isNil(data.previousValue) && this.form) {
            this.form.get(this.controlName).setValue(this.newValue);
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.updateInterval);
    }

    getValue(data: any): Observable<any> {
        return new Observable((observer) => {
            if (Array.isArray(data)) {
                const listPromises = [];
                for (const obj of data) {
                    if (obj?.get) { // Document reference
                        listPromises.push(obj.get().then(doc => ({id: doc.id, ...doc.data()})));
                    }
                }
                if (listPromises.length > 0) {
                    Promise.all(listPromises).then((values) => {
                        this.resolveData(values).subscribe((res) => {
                            observer.next(res);
                        });
                    });
                } else {
                    this.resolveData(data).subscribe((res) => {
                        observer.next(res);
                    });
                }
            } else {
                let changedData = data;
                if (lodash.isObject(data)) {
                    changedData = data[this.controlName];
                }
                this.resolveData(changedData).subscribe((res) => {
                    observer.next(res);
                });
            }
        }).pipe(take(1));
    }

    resolveData(data: any) {
        return new Observable((observer) => {
            if (data?.get) { // Document reference
                data.get().then(docRef => {
                    const doc = {id: docRef.id, ...docRef.data()};
                    if (this.customShowValue) {
                        observer.next(this.customShowValue(doc));
                    } else if (lodash.isNil(doc) || lodash.isEmpty(doc)) {
                        observer.next(this.translate('approvals-requests.feedback-modal.empty-value'));
                    } else {
                        observer.next(doc);
                    }
                });
            } else {
                if (this.customShowValue) {
                    observer.next(this.customShowValue(data));
                } else if (typeof data === 'boolean') {
                    observer.next(data ? this.translate('system.yes') : this.translate('system.no'));
                } else if (lodash.isNil(data) || (!lodash.isNumber(data) && lodash.isEmpty(data))) {
                    observer.next(this.translate('approvals-requests.feedback-modal.empty-value'));
                } else {
                    observer.next(data);
                }
            }
        }).pipe(take(1));
    }

    getTitle() {
        if (this.item && this.item.historicalData) {
            if (this.item && this.item.historicalData && this.getFeedbackResult() === STATUS_REJECTED) {
                return 'approvals-history.historical-data.dropdown-title-changes-rejected';
            } else {
                return 'approvals-history.historical-data.dropdown-title';
            }
        }
    }

    close() {
        if (this.customDropdown) {
            this.customDropdown.close();
        }
    }

    findDataByPropertyInBackup() {
        if (this.property && this.item.historicalData && this.item.historicalData.data) {
            return this.item.historicalData.data.find((item: any) => item.reference.id === this.item.id &&
                this.property?.includes(item.property));
        }
    }

    hasHistoricalDataForThisProperty() {
        if (!this.userService.loggedUser.finsteinUser && this.item && this.property && this.item.historicalData &&
            this.item.historicalData.data) {
            return this.oldValue !== this.newValue;
        }
    }

    getFeedbackResult() {
        const historyFound = this.item.historicalData.data.find(item => item.property === this.property &&
            this.item.id === item.reference.id);
        if (historyFound) {
            return historyFound.result;
        }
    }

    getCustomValue(value: any): string {
        if (Array.isArray(value)) {
            return value.join(', ');
        }
        return value ? value : this.translate('approvals-requests.feedback-modal.empty-value');
    }
}
