<div #customDropdown *ngIf="historicalDataObs | async as data" class="row hist-dropdown d-flex align-items-center justify-content-end disable-user-select">
    <div #dropdownTitle *ngIf="this.expanded" class="dropdown-title d-flex align-items-center slide-left">
        <p translate>approvals-history.historical-data.title</p>
    </div>
    <div #dropdownDetail *ngIf="this.expanded && this.detailed" class="dropdown-detail p-4 scale-up-tr">
        <div *ngIf="data.process">
            <div class="row mt-2">
                <div class="col-1">
                    <div class="dot process"></div>
                </div>
                <div class="col text-muted mt-0" translate>
                    approvals-history.status.description.processed
                </div>
            </div>
            <p class="ml-6">
                {{data.process.userName}}
            </p>
        </div>
        <div *ngIf="data.confirm">
            <div class="row">
                <div class="col-1">
                    <div class="dot confirm"></div>
                </div>
                <div class="col text-muted mt-0" translate>
                    approvals-history.status.description.approved
                </div>
            </div>
            <p class="ml-6">
                {{data.confirm.userName}}
            </p>
        </div>
        <div *ngIf="data.inform">
            <div class="row">
                <div class="col-1">
                    <div class="dot inform"></div>
                </div>
                <div class="col text-muted mt-0" translate>
                    approvals-history.status.description.informed
                </div>
            </div>
            <p class="ml-6">
                {{data.inform.userName}}
            </p>
        </div>
        <div *ngIf="data.historicalGroupId">
            <p id="all-changes"
                class="text-center cursor-pointer text-primary"
                (click)="loadAllChange(data.historicalGroupId)"
                translate
            >
                approvals-history.see-all-changes
            </p>
        </div>
    </div>
    <i class="dropdown-icon material-icons cursor-pointer d-flex align-items-center justify-content-center"
        matRipple
        [matTooltip]="getTooltipDetailMessage()" (click)="toggle()">
        schedule
    </i>
</div>
