import { AfterContentInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import Inputmask from 'inputmask';
@Directive({
    selector: '[appNumericField]',
})
export class NumericFieldDirective implements AfterContentInit {
    /* Apply only to inputs of type number */
    @Input() type: string;
    /* Formatting Type */
    @Input() numericType: 'float' | 'integer' = 'integer';

    domElement: any;

    regex = {
        integer: new Inputmask({
            regex: '\\d*',
            showMaskOnFocus: false,
            showMaskOnHover: false,
        }),
        float: new Inputmask({
            regex: '^\\d*(\\.\\d{1,2})?$',
            showMaskOnFocus: false,
            showMaskOnHover: false,
        }),
    };

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.domElement = this.elementRef.nativeElement;
    }

    ngAfterContentInit() {
        if (this.type === 'number') {
            this.renderer.setAttribute(this.domElement, 'autocomplete', 'nope');
            this.regex[this.numericType].mask(this.domElement);
        }
    }
}
